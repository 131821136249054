<template lang="pug">
	svg(
		alt="",
		aria-hidden="true",
		height="20",
		style="height: auto; width: 100%",
		viewBox="0 0 100 100",
		width="20",
		xmlns="http://www.w3.org/2000/svg"
	)
		path(
			d="M24.42 99.16a2.84 2.84 0 0 0 2 .84 2.74 2.74 0 0 0 2-.84L75.58 52a2.81 2.81 0 0 0 0-4L28.42.83a2.83 2.83 0 1 0-4 4L69.58 50 24.42 95.16a2.82 2.82 0 0 0 0 4z",
			fill="currentColor"
		)</template>
